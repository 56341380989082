import { FC, useEffect } from 'react';
import { Amplify } from 'aws-amplify';
import { useSelector } from 'react-redux';
import { RootState } from './redux/store'; //
import MainLayout from './layouts//MainLayout';
import SignIn from './features/SignIn/containers/SignInPage';
import config from './config.json';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';


export const configureAmplify = () => {
  Amplify.configure({
    Auth: {
      Cognito: {
        userPoolId: config.cognitoUserPoolId,
        userPoolClientId: config.userPoolWebClientId
      }
    }
  });
};

// Call the configuration function immediately
configureAmplify();


const App: FC = () => {
    const isAuth = useSelector((state: RootState) => state.user.isAuth);

    return (
        <div>
            <Router>
                <Routes>
                    <Route
                        path="/login"
                        element={isAuth ? <Navigate to="/" /> : <SignIn />}
                    />
                    <Route
                        path="*"
                        element={
                            isAuth ? (
                                <MainLayout />
                            ) : (
                                <Navigate to="/login" />
                            )
                        }
                    />
                </Routes>
            </Router>
        </div>
    );
}

export default App;
