import { signIn, fetchUserAttributes, fetchAuthSession } from '@aws-amplify/auth';
import { AuthResponse, UserProfile } from '../types';

// Ensure that cognitoFetchUser returns the same AuthResponse format with tokens and profile
const cognitoFetchUser = async (email: string, password: string): Promise<AuthResponse> => {
  try {
    // Sign in with Cognito/Amplify
    const signInOutput = await signIn({ 
      username: email, 
      password 
    });
    
    if (!signInOutput.isSignedIn) {
      throw new Error('Sign in was not successful');
    }
    
    // Get the current authenticated user's session to get tokens
    const session = await fetchAuthSession();
    if (!session.tokens?.accessToken) {
      throw new Error('No access token available after sign in');
    }
    const accessToken = session.tokens.accessToken.toString();
    
    // Get additional user attributes if needed
    const userAttributes = await fetchUserAttributes();
    
    // Extract profile information from the response
    const profile: UserProfile = {
      email: email,
      name: email.split('@')[0],
      role: userAttributes['custom:role'] || 'user',
      userId: userAttributes.sub || '',
    };

    // Return the full AuthResponse with tokens and profile in the same format
    // as the original loginService for compatibility
    return {
      accessToken,
      profile,
    };
  } catch (error: any) {
    console.error('Error authenticating with Cognito:', error);
    
    // Provide more specific error messages based on the error
    if (error.name === 'UserNotConfirmedException') {
      throw new Error('Please verify your email address before signing in');
    } else if (error.name === 'NotAuthorizedException') {
      throw new Error('Incorrect username or password');
    } else if (error.name === 'UserNotFoundException') {
      throw new Error('User does not exist');
    }
    
    throw new Error(error.message || 'Failed to authenticate user');
  }
};

export default cognitoFetchUser;