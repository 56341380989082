import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { AuthResponse, UserState } from 'src/types';
// import fetchUser from '../../services/loginService';
import fetchUser from '../../services/cognitoFetchUser';

const checkAuthStatus = (): boolean => {
  const accessToken = localStorage.getItem('MonsterMusicIo.accessToken');
  const expiryTime = localStorage.getItem('MonsterMusicIo.expiryTime');
  const currentTime = new Date().getTime();

  if (accessToken && expiryTime && parseInt(expiryTime) > currentTime) {
    return true;
  }
  localStorage.removeItem('MonsterMusicIo.accessToken');
  localStorage.removeItem('MonsterMusicIo.expiryTime');
  localStorage.removeItem('MonsterMusicIo.userProfile');
  return false;
};

const initialState: UserState = {
  isAuth: checkAuthStatus(),
  loading: false,
  error: null,
  profile: JSON.parse(localStorage.getItem('MonsterMusicIo.userProfile') || 'null'),
  accessToken: localStorage.getItem('MonsterMusicIo.accessToken'),
};

export const loginUser = createAsyncThunk<
  AuthResponse,
  { email: string; password: string },
  { rejectValue: string }
>(
  'user/loginUser',
  async ({ email, password }, { rejectWithValue }) => {
    try {
      const response = await fetchUser(email, password);
      const { accessToken, profile } = response;

      // Store token localStorage
      localStorage.setItem('MonsterMusicIo.accessToken', accessToken);
      localStorage.setItem('MonsterMusicIo.userProfile', JSON.stringify(profile));
      const expiryTime = new Date().getTime() + 60 * 60 * 1000; // 1 hour
      localStorage.setItem('MonsterMusicIo.expiryTime', expiryTime.toString());

      return response;
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logout: (state) => {
      state.isAuth = false;
      state.profile = null;
      state.accessToken = null;
      localStorage.clear();
    },
    refreshAccessToken: (state, action: PayloadAction<string>) => {
      state.accessToken = action.payload;
      localStorage.setItem('MonsterMusicIo.accessToken', action.payload); // Sync with localStorage
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.fulfilled, (state, action: PayloadAction<AuthResponse>) => {
        const { accessToken, profile } = action.payload;
        state.isAuth = true;
        state.profile = profile;
        state.accessToken = accessToken;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.error = action.payload || 'Login failed';
        state.loading = false;
      });
  },
});

export const { logout, refreshAccessToken } = userSlice.actions;
export default userSlice.reducer;
